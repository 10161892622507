import ServerFetch from 'server-fetch';

const searchPath = '/api/api/compositions';
const singlePath = '/api/api/composition';

export function $search (archetype, search, query, folderId = undefined, teamId = undefined) {
    const body = {};
    if (folderId) {
        body.folder_id = folderId;
    }

    if (teamId) {
        body.team_id = teamId;
    }

    if (search) {
        body.search = JSON.stringify(search);
    }

    return makePostRequest(
        generateUrl(searchPath + '/' + archetype, query),
        body
    );
}

export async function $fullList(archetype, query, folderId = undefined, teamId = undefined) {
    const response = await $list(archetype, query, folderId, teamId);
    const { message, status } = response;
    let { meta: {count: totalCount, per_page: perPage}, results} = message;

    if (totalCount <= perPage) {
        return response;
    }

    let page = 2;
    let pagesToFetch = [];
    while (totalCount > perPage) {
        pagesToFetch.push(page);
        totalCount = totalCount - perPage;
        page++;
    }
    const data = await Promise.all(
        pagesToFetch.map(page => $list(archetype, [...(query || []), `page=${page}`], folderId, teamId))
    );

    return {
        status,
        message: {
            results: [...results, ...data.map(response => response.message.results).flat()],
        }
    }
}

export function $list (archetype, query, folderId = undefined, teamId = undefined) {
    const body = {};
    if (folderId) {
        body.folder_id = folderId;
    }

    if (teamId) {
        body.team_id = teamId;
    }

    return makePostRequest(
        generateUrl(searchPath + '/' + archetype, query),
        body
    );
}

export function $show (archetype, uuid, folderId = undefined, teamId = undefined) {
    return makeGetRequest(
        generateUrl(singlePath + '/' + archetype + '/' + uuid, false, folderId, teamId)
    );
}

export function $create (archetype, content, folderId = undefined, teamId = undefined) {
    return makePostRequest(
        generateUrl(singlePath + '/' + archetype, false, folderId, teamId),
        {device_id: ServerFetch.getPortal(), content: JSON.stringify(content)});
}

export function $update (archetype, uuid, content, folderId = undefined, teamId = undefined) {
    return makePutRequest(
        generateUrl(singlePath + '/' + archetype + '/' + uuid, false, folderId, teamId),
        {device_id: ServerFetch.getPortal(), content: JSON.stringify(content)});
}

export function $delete (archetype, uuid, folderId = undefined, teamId = undefined) {
    return makeDeleteRequest(
        generateUrl(singlePath + '/' + archetype + '/' + uuid, false, folderId, teamId));
}

export function $undelete (archetype, uuid, folderId = undefined, teamId = undefined) {
    return makePostRequest(
        generateUrl(singlePath + '/' + archetype + '/' + uuid + '/undelete', false, folderId, teamId),
        {});
}

export function $addConsent (folderId, teamId, data) {
    return makePostRequest(
        generateUrl('/api/folder/' + folderId + '/consent', false, false, teamId),
        data
    );
}

export function $$delete (path, data, teamId = undefined) {
    return makeDeleteRequest(
        '/api/' + path.join('/') + addTeamToQuery('?', teamId), data
    );
}

export function post(path, data) {
    return makePostRequest(
        '/api/' + path, data
    );
}

export function $post (path, data, teamId = undefined) {
    const addTeamId = teamId ? addTeamToQuery('?', teamId) : '';
    return makePostRequest(
        '/api/' + path.join('/') + addTeamId, data
    );
}

export function $put (path, data, teamId = undefined) {
    return makePutRequest(
        '/api/' + path.join('/') + addTeamToQuery('?', teamId), data
    );
}

export function $webPost (path, data, teamId = undefined) {
    return makePostRequest(
        '/webapi/' + path.join('/') + addTeamToQuery('?', teamId), data
    );
}

export function $get (path, teamId = undefined) {
    return makeGetRequest(
        '/api/' + path.join('/') + addTeamToQuery('?', teamId)
    );
}

export function get(path) {
    return makeGetRequest(`/api${path}`);
}

export function $webGet (path, teamId = undefined) {
    return makeGetRequest(
        '/webapi/' + path.join('/') + addTeamToQuery('?', teamId)
    );
}

export function patch(path, data) {
    return new Promise((resolve, reject) => {
        ServerFetch.patchRequest(ServerFetch, `/api${path}`, data, handleResponse.bind(null, resolve, reject));
    });
}

/**************************************
 *  Private Code
 ****************************************/

function generateUrl (url, query, folderId, teamId, search = undefined) {
    let querySegment = addSearchToQuery(
        addTeamToQuery(
            addFolderToQuery(
                (
                query || []),
                folderId),
            teamId),
        search).join('&');

    if (querySegment !== ''){
        querySegment = `?${querySegment}`;
    }


    return `${url}${querySegment}`

}

export function makeGetRequest (query, apiVersion = 1) {
    return new Promise((resolve, reject) => {
        ServerFetch.getRequest(ServerFetch, query, handleResponse.bind(null, resolve, reject), {}, apiVersion);
    });
}

export function makePostRequest (query, data, apiVersion = 1, params = {}, headers = {}) {
    return new Promise((resolve, reject) => {
        ServerFetch.postRequest(ServerFetch, query, data, handleResponse.bind(null, resolve, reject), {}, apiVersion, params, headers);
    });
}

export function makePutRequest (query, data, apiVersion = 1) {
    return new Promise((resolve, reject) => {
        ServerFetch.putRequest(ServerFetch, query, data, handleResponse.bind(null, resolve, reject), apiVersion);
    });
}

export function makeDeleteRequest (query, data) {
    return new Promise((resolve, reject) => {
        ServerFetch.deleteRequest(ServerFetch, query, data, handleResponse.bind(null, resolve, reject));
    });
}

function handleResponse (resolve, reject, response, status) {
    if (status < 400) {
        resolve(response);
    } else {
        reject({
            response: response,
            status: status
        });
    }
}

function addSearchToQuery (safeQuery, search) {
    if (search) {
        return safeQuery.concat('search=' + encodeURIComponent(JSON.stringify(search)));
    }

    return safeQuery;
}

function addFolderToQuery (safeQuery, folderId) {
    return addToQuery(safeQuery, 'folder_id', folderId);
}

function addTeamToQuery (safeQuery, teamId) {
    return addToQuery(safeQuery, 'team_id', teamId);
}

function addToQuery (safeQuery, key, value) {
    if (value) {
        return safeQuery.concat(key + '=' + encodeURIComponent(value));
    }

    return safeQuery;
}
