import { IFolderSelectorParam, IRole } from 'phr-api-client';
import { terService } from 'services/ter.service';
import { CompositionService, ICompositionCommonArgs, ICreateArgs, IUpdateArgs } from './composition.service';
import { Resource } from 'models/compositions/Resource';
import * as lodash from 'lodash';
import mediaResourceService from './media-resource/media-resource.service';
import { CreateResourceState, getTriggerEngineContent } from 'components/user/resource/create/validator';

interface IResourceCreateArgs extends ICreateArgs {
    file?: File;
    lozenge?: {
        title: string;
        text: string;
    };
    content: Resource;
}

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
interface IResourceUpdateArgs extends IUpdateArgs {
    file?: File;
}

interface IResourceDeleteArgs extends ICompositionCommonArgs {
    mediaResourceUuid?: string;
}

export const RESOURCE_TAGS_LABEL_TYPE = 'resourceTags';
export const PUBLIC_RESOURCE_LABEL_TYPE = 'publicResource';
export const PUBLIC_RESOURCE_LABEL_CONTEXT = 'isPublic';

class ResourcesService extends CompositionService {

    get archetypeName(): string {
        return 'coopResource';
    }

    getByCategory({ role, categoryUuid, folderId }: { role?: IRole; categoryUuid: string; folderId?: IFolderSelectorParam }): Promise<any> {
        const search = { 'content.category.category_id': categoryUuid };
        return this.search({ folderId, search })
            .then((response) => {
                return lodash.get(response, 'message.results', []);
            });
    }

    getPaginatedData({ categoryUuid }: { categoryUuid: string }): Promise<any> {
        const search = { 'content.category.category_id': categoryUuid };
        return this.search({ folderId: 'team', search })
            .then((response) => {
                return {
                    totalResultCount: response.message.total,
                    resultsPageData: response.message.results,
                };
            });
    }

    create({ role, content, file, lozenge, folderId, archetypeName = this.archetypeName }: IResourceCreateArgs) {
        let mediaResourceAwaiter = Promise.resolve({});
        if (content.resource_type === 'media_resource') {
            mediaResourceAwaiter = mediaResourceService.createMedia(
                {
                    mediaResourceParams: {
                        description: content.description,
                        label: content.label,
                        categoryUuid: content.category[0].category_id
                    },
                    file
                });
        }
        return mediaResourceAwaiter
            .then(({ mediaResourceUuid }: any) => {
                if (mediaResourceUuid) {
                    content['media_resource_uuid'] = mediaResourceUuid;
                }
                if (!lozenge) {
                    return super.create({ role, content, folderId, archetypeName });
                }
            })
            .then((response) => {
                if (!lozenge) {
                    return response;
                }

                const resourceState: CreateResourceState = {
                    type: content.resource_type,
                    label: content.label,
                    url: content.url,
                    phone: content.phone,
                    html: content.html_content,
                    description: content.description,
                    mediaFile: null,
                    mediaFileUuid: content.media_resource_uuid,
                    category: content.category,
                    lozengeTitle: lozenge ? lozenge.title : null,
                    lozengeText: lozenge ? lozenge.text : null,
                    sendPush: false,
                    notifyTimeline: false
                };

                return terService.createTer({
                    action: 'allocateResourceWithMessage',
                    data: getTriggerEngineContent(resourceState)
                });
            });
    }

    update({ uuid, role, content, folderId, file, archetypeName = this.archetypeName }: IResourceUpdateArgs) {
        let mediaResourceAwaiter = Promise.resolve({} as any);
        if (content.resource_type === 'media_resource') {
            // file and resource already existing
            if (content.media_resource_uuid) {
                mediaResourceAwaiter = mediaResourceService.updateMedia(
                    {
                        mediaResourceParams: {
                            description: content.description,
                            label: content.label,
                            categoryUuid: content.category[0].category_id
                        },
                        file,
                        mediaResourceUuid: content.media_resource_uuid
                    });
                // attached media does not exist but file does -
                // it might happen when resource type has changed recently to media_resource
                // - so need to create media resource from scratch
            } else if (file) {
                mediaResourceAwaiter = mediaResourceService.createMedia(
                    {
                        mediaResourceParams: {
                            description: content.description,
                            label: content.label,
                            categoryUuid: content.category[0].category_id
                        },
                        file,
                    });
            }
        } else {
            // if resource was previously has media_resource type and now doesn't we need to remove
            // previously attached file
            if (content.media_resource_uuid) {
                mediaResourceAwaiter = mediaResourceService.delete(content.media_resource_uuid, folderId);
            }
        }

        return mediaResourceAwaiter
            .then((result: any) => {
                if (result && result.mediaResourceUuid) {
                    content.media_resource_uuid = result.mediaResourceUuid;
                }
                return super.update({ uuid, role, content, folderId, archetypeName });
            });
    }

    delete({ uuid, mediaResourceUuid, role, folderId, archetypeName = this.archetypeName }: IResourceDeleteArgs) {
        let mediaResourceAwaiter = Promise.resolve({} as any);
        if (mediaResourceUuid) {
            mediaResourceAwaiter = mediaResourceService.delete(mediaResourceUuid, folderId);
        }
        return mediaResourceAwaiter
            .then(() => {
                return super.delete({ uuid, role, folderId, archetypeName });
            });
    }

    getIsPublicResource(labelsArray) {
        let isPublicResource = false;
        if (labelsArray) {
            const publicLabel = labelsArray.filter(label => label.context === 'isPublic');
            if (publicLabel.length !== 0) {
                isPublicResource = true;
            }
        }
        return isPublicResource;
    }

}

export const resourcesService = new ResourcesService();
