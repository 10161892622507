import React, { useState, useEffect, FC } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker';
// interfaces
import { LettersService } from 'services/letters.service';
import { LetterStationeryItem } from '../letters.interface';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

type LetterPdfPreviewProps = {
    stationery: LetterStationeryItem;
    letter: string;
    letterService: LettersService;
};

export const LetterPdfPreview: FC<LetterPdfPreviewProps> = (props) => {
    const { stationery, letter, letterService } = props;

    const [isLoadingPreview, setIsLoadingPreview] = useState<boolean>(true);
    const [previewUrl, setPreviewUrl] = useState<string>();

    useEffect(() => {
        async function loadPdfPreview() {
            const response = await letterService.getPdfPreview({
                header: stationery.header.htmlFilename,
                body: letter,
                footer: stationery.footer.htmlFilename,
            });

            const blob = new Blob([response], { type: 'application/pdf' });

            const url = window.URL.createObjectURL(blob);

            setPreviewUrl(url);
            setIsLoadingPreview(false);
        }

        loadPdfPreview();
    }, [letter, stationery, letterService]);

    return (
        <div>
            {isLoadingPreview ? (
                <span>Loading...</span>
            ) : (
                <div>
                    <Document file={previewUrl}>
                        <Page pageNumber={1} width={300} />
                    </Document>
                    <div>
                        <a href={previewUrl} download="letter-preview.pdf">
                            Load preview
                        </a>
                    </div>
                </div>
            )}
        </div>
    );
};
