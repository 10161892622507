import React, { useState, Fragment } from 'react';
import { Modal } from 'common/ui/modal/Modal';
import BulkOperationConfigurationComponent from './BulkOperationConfigurationComponent';
import { BulkOperationCostEstimateSummary } from './BulkOperationCostEstimateSummary';
import { FolderSelectionState, useFolderSelectionState } from 'components/dataSource/FolderSelectionState';
import { BulkOperationPreviewBuilder } from './BulkOperationPreviewBuilder';
import { bulkOperationService } from 'services/bulk-operation.service';
import { portalToast } from 'ui/toast/Toast';
import { Column, Row } from 'ui';
import ErrorView from 'components/errorbox';
import './BulkOperationPreview.scss';
import { SelectionStateContainer } from 'models/AdvancedSearchDefinition';
import { convertToOutputTzDate, formatDate } from 'common/datetime/DateTime';

export const BO_NAME_LENGTH_MIN = 3;
export const BO_NAME_LENGTH_MAX = 255;

export function BulkOperationSetupModal<S>(props: {
    actionComponent: BulkOperationConfigurationComponent<S>;
    actionName: string;
    onClose: () => void;
    onPreview: () => void;
    initialState?: S;
}): JSX.Element {
    return (
        <Modal
            size={'lg'}
            title={props.actionName}
            buttons={[{
                classNames: 'btn btn-primary',
                callback: () => { if (props.actionComponent.validate()) { props.onPreview(); } },
                label: 'Preview'
            }]}
            onClose={() => {
                props.onClose();
            }}
        >
            {props.actionComponent.SetupComponent()}
        </Modal>
    );
}

export function BulkOperationPreviewModal(props: {
    actionPreviewComponent: BulkOperationPreviewBuilder<unknown>;
    actionName: string;
    onClosePreview: () => void;
    onCloseAll: () => void;
    selectionStateContainer: SelectionStateContainer;
    queryName: string;
    queryResultsLastUpdated;
}) {
    const [errors, onError] = useState<string[]>(undefined);

    const [isFetching, setIsFetching] = useState<boolean>(false);

    const onConfirm = () =>
    {
        if(isFetching) {
            return;
        }

        if (!validate(props.actionPreviewComponent, onError))
        {
            return;
        }

        const bulkOperationName: string = props.actionPreviewComponent.getBulkOperationName();

        setIsFetching(true);
        bulkOperationService.createBulkOperationRequest({
            actions: props.actionPreviewComponent.getBulkOperations(),
            boName: bulkOperationName,
            selectionStateContainer: props.selectionStateContainer,
        })
            .then(() => {
                showBOSuccessToast(bulkOperationName);
                setIsFetching(false);
                props.onCloseAll();
            })
            .catch((e) => {
                console.error(e);
                showBOFailureToast(bulkOperationName);
                setIsFetching(false);
            });
    };

    const selectionStateRepresentation = useFolderSelectionState(props.selectionStateContainer.selectionState);
    const selected = selectionStateRepresentation.selectionCount;
    const total = selectionStateRepresentation.folderCount;

    return (
        <Modal
            size={'lg'}
            title={'Preview: ' + props.actionName}
            onClose={() => { props.onClosePreview(); }}
            buttons={[
                {
                    classNames: 'btn btn-secondary',
                    callback: () => { props.onClosePreview(); },
                    label: 'Back'
                },
                {
                    classNames: 'btn btn-primary',
                    callback: onConfirm,
                    label: `Confirm action for ${selected} of ${total} patients`,
                    disabled: isFetching,
                }
            ]}>
            {
                <div className="action-preview-body">
                    <BulkOperationPreviewHeader
                        queryName={props.queryName}
                        queryResultsLastUpdated={props.queryResultsLastUpdated}
                        actionPreviewComponent={props.actionPreviewComponent}
                        selectionState={props.selectionStateContainer.selectionState}
                        errors={errors}
                    />
                    {props.actionPreviewComponent.PreviewComponents()}
                </div>
            }
        </Modal>
    );
}

function validate(actionPreviewComponent: BulkOperationPreviewBuilder<unknown>, onError: (errors: string[]) => void): boolean {
    const errors: string[] = [];
    const bulkOperationName: string = actionPreviewComponent.getBulkOperationName();
    const bulkOperationNameNormalised = bulkOperationName ? bulkOperationName.trim() : '';
    if (!bulkOperationNameNormalised) {
        errors.push('You must enter a name for this bulk operation.');
    }
    else if (bulkOperationNameNormalised.length < BO_NAME_LENGTH_MIN || bulkOperationNameNormalised.length > BO_NAME_LENGTH_MAX) {
        errors.push('The bulk operation name must be between 3 and 255 characters.');
    }

    onError(errors);
    return errors.length == 0;
}

function showBOSuccessToast(boName: string) {
    return portalToast.success({
        title: 'Bulk operation request succeeded',
        content: `Bulk operation request '${boName}' was created and is being processed.`
    });
}

function showBOFailureToast(boName: string) {
    return portalToast.error({
        title: 'Bulk operation request failed',
        content: `Bulk operation request '${boName}' was not created.`
    });
}


function BulkOperationPreviewHeader(props: {
    actionPreviewComponent: BulkOperationPreviewBuilder<unknown>;
    selectionState: FolderSelectionState; errors: string[];
    queryName: string;
    queryResultsLastUpdated;
}): JSX.Element {
    const date = formatDate(convertToOutputTzDate(props.queryResultsLastUpdated), 'nhs_date_with_time');

    return (
        <Fragment>
            <ErrorView errors={props.errors} />
            <div className="row action-preview-header">
                <Column sm={7}><BulkOperationNameInput actionPreviewComponent={props.actionPreviewComponent} /></Column>
                <Column sm={3}>
                    <Row><b>{props.selectionState.getSelectionCount()}/{props.selectionState.getFolderCount()} patients selected</b></Row>
                    <Row><b>Filter: {props.queryName || ''}</b></Row>
                    <Row><b>Results last updated: {date}</b></Row>
                </Column>
                <Column sm={2}><BulkOperationCostEstimateSummary previewComponent={props.actionPreviewComponent} /></Column>
            </div>
        </Fragment>
    );
}

function BulkOperationNameInput(props: { actionPreviewComponent: BulkOperationPreviewBuilder<unknown> }): JSX.Element {
    const [boName, onBONameChange] = useState<string>(props.actionPreviewComponent.getBulkOperationName());

    const handleBONameChange = (e) => {
        const newName: string = e.target.value;
        props.actionPreviewComponent.setBulkOperationName(newName);
        onBONameChange(newName);
    };

    return (
        <input
            type="text"
            name="boName"
            minLength={BO_NAME_LENGTH_MIN}
            maxLength={BO_NAME_LENGTH_MAX}
            placeholder="Bulk operation name"
            className={'form-control bo-name'}
            value={boName ? boName : ''}
            onChange={(e) => handleBONameChange(e)}
        />
    );
}
