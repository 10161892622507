import PatientBar from 'components/patient-bar';
import { Demographics } from 'models/compositions/Demographics';
import React, {useState, useEffect }from 'react';
import { demographicsService } from 'services/demographics.service';
import { folderService } from 'services/folder.service';
import { patientsService } from 'services/patients.service';
import { getPersonPrimaryIdentifierFromUserFolder } from 'common/personPrimaryIdentifierHelpers';
import { teamPreferencesService } from 'services/team-preferences.service';
import { terService } from 'services/ter.service';
import { Column, ConfirmModal, Folder, Row } from 'ui';
import Modal from 'ui/modal';

const RegistrationStatusLabels = {
    [patientsService.STATUS.REGISTERED]: 'Registered',
    [patientsService.STATUS.INVITED]: 'Invited',
    [patientsService.STATUS.PENDING]: 'Pending',
};

const PatientBarModal = ({ folderId, teamId }) => {
    const [folderDetails, setFolderDetails] = useState<any>({});
    const [patientData, setPatientData] = useState<any>();
    const [emailConfirmationModal, setEmailConfirmationModal] = useState<boolean>(false);
    const [personPrimaryIdentifier, setPersonPrimaryIdentifier] = useState<any>();
    const [newEmail, setNewEmail] = useState<string>('');
    const [notification, setNotification] = useState<any>({});
    const [notificationModal, setNotificationModal] = useState<boolean>(false);
    const [teamPreferences, setTeamPreferences] = useState<any>();
    const [humanReadableLabels, setHumanReadableLabels] = useState<any>(); 

    useEffect(() => {
        loadData();
        loadFolderDetails();
        getTeamPreferences();
    }, []);

    useEffect(() => {
        teamPreferences && setHumanReadableLabels(teamPreferences.primary_identifier && teamPreferences.primary_identifier.human_readable_labels);
        teamPreferences && folderDetails && patientData && getPersonPrimaryIdentifier();
    }, [teamPreferences, folderDetails])

    const getRegistrationStatus = (status): string => {
        return RegistrationStatusLabels[status] || null;
    };

    const loadFolderDetails = (): void => {
        if (folderId) {
            folderService.getFolderById({ folderId }).then((folder) => {
                setFolderDetails(folder);
            }).catch((e) => {
                console.error('Error during loading folderDetails data', e);
            });
        }
    };

    const getTeamPreferences = (): void => {
        teamPreferencesService.getFirst({}).catch(() => {
            return teamPreferencesService.defaultProperties;
        }).then((teamPreferencesData) => {
            setTeamPreferences(teamPreferencesData);
        });
    };

    const loadData = (): any => {
        return demographicsService.getFirst({ folderId })
            .then((demographics) => {
                setPatientData(demographics.content);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const onSubmitEmail = (): any => {
        const email = newEmail;
        setNewEmail('');
        setEmailConfirmationModal(false);
        return terService.createTer({ action: 'changeEmail', data: { email } })
            .then(() => {
                setNotification(
                    {
                        title: 'Request was sent',
                        text: 'Request for change email was sent'
                    });
                setNotificationModal(true);
            })
            .catch(() => {
                setNotification(
                    {
                        title: 'Request was failed',
                        text: 'Request for change email was failed. Please try again later.'
                    });
                setNotificationModal(true);
            });
    };

    const closeChangeEmailModal = (): void => {
        setEmailConfirmationModal(false);
    };

    const getPersonPrimaryIdentifier = (): void => {
        const ppiData = getPersonPrimaryIdentifierFromUserFolder(folderDetails, humanReadableLabels, patientData);
        setPersonPrimaryIdentifier(ppiData);
    };

    const confirmChangeEmail = (email): Promise<void> => {
        setNewEmail(email);
        setEmailConfirmationModal(true);
        return Promise.resolve();
    };


    return (
        <div>
            {patientData && (
                <PatientBar
                    patientData={patientData}
                    folderData={folderDetails}
                    onSubmitEmail={(email) => confirmChangeEmail(email)}
                    personPrimaryIdentifier={personPrimaryIdentifier}
                />
            )
            }
            {emailConfirmationModal && (
                <ConfirmModal
                    onConfirm={() => onSubmitEmail()}
                    onClose={() => closeChangeEmailModal()}>
                    Are you sure? <br />
                    <br />
                    New Email is <strong>{newEmail}</strong>
                </ConfirmModal>
            )}
            {notificationModal && (
                <Modal title={notification.title}
                    buttons={[
                        { type: 'callback', callback: () => setNotificationModal(false), label: 'Ok' }
                    ]}
                    onClose={() => setNotificationModal(false)}
                >
                    {notification.text}
                </Modal>
            )}
            <Row className="patient-details__tab-title">
                <Column sm="12" md="6">
                    <Row>
                        <Column sm="12" md="6">
                            {folderDetails &&
                                <h3>Status: {getRegistrationStatus(folderDetails.status)}</h3>}
                        </Column>
                    </Row>
                </Column>
            </Row>
        </div>
    );
};

export default PatientBarModal;
