import React, { FC } from 'react';
// interfaces
import { AdvancedSearchCriterionNumberInputProps } from './AdvancedSearchCriterionNumberInput.interface';

export const AdvancedSearchCriterionNumberInput: FC<AdvancedSearchCriterionNumberInputProps> =
    (props) => {
        const valueWithDefault = props.value ? props.value : '';

        return (
            <input
                type={'number'}
                value={valueWithDefault}
                onChange={props.onChange}
                className={'form-control'}
            />
        );
    };
