import React, { useState } from 'react';
import { FolderSelectionState } from '../dataSource/FolderSelectionState';
import { QuestionnaireCreateForm } from '../user/proms/create/questionnaireForm';
import { SendQuestionnaireState, validate, normalise } from '../user/proms/create/questionnaireValidator';
import BulkOperationConfigurationComponent from './BulkOperationConfigurationComponent';
import { BulkOperationCostEstimatorNone } from './BulkOperationCostEstimator';
import { BulkOperationPreviewBuilder, BulkOperationPreviewItem } from './BulkOperationPreviewBuilder';
import { Timeline, TimelineContext, TimelineTranslations } from 'msk-timeline';
import marked from 'marked';
import { BulkOperationRequestAction, BulkOperationRequestSendPushNotification, BulkOperationRequestSendQuestionnaire } from 'models/BulkOperationRequestAction';
import { TriggerEngineRequestContent } from 'models/TriggerEngineRequestContent';
import { NOTIFICATION_TYPE } from 'services/push-notifications.service';
import { getTriggerEngineContent } from 'components/user/proms/create/questionnaireValidator';
import { defaults } from 'components/user/proms/create/questionnaireValidator'

const initialFormState: SendQuestionnaireState = {
    questionnaireName: '',
    questionnaireId: '',
    lozengeTitle: '',
    lozengeContent: '',
    popupTitle: '',
    popupContent: '',
    sendPush: false
};

export class BulkOperationQuestionnaireConfigurator extends BulkOperationConfigurationComponent<SendQuestionnaireState> {
    public validate: () => boolean;
    constructor() {
        super();
    }

    public getActionName(): string {
        return 'Send Questionnaire';
    }

    public getInitialFormState(): SendQuestionnaireState {
        return initialFormState;
    }

    public SetupComponent(): JSX.Element {
        const [currentErrors, onErrors] = useState<string[]>([]);
        const { currentFormState, onFormStateChange } = this.useFormState();

        this.validate = () => validate(this.getFormState(), onErrors);

        return (
            <QuestionnaireCreateForm
                form={currentFormState}
                onChange={(c) => onFormStateChange({ ...currentFormState, ...c })}
                errors={currentErrors}
            />
        );
    }

    public getPreviewBuilder(selectionState: FolderSelectionState): BulkOperationPreviewBuilder<SendQuestionnaireState> {
        return new BulkOperationQuestionnairePreviewBuilder(selectionState, normalise(this.getFormState()));
    }
}

export class BulkOperationQuestionnairePreviewBuilder extends BulkOperationPreviewBuilder<SendQuestionnaireState> {
    public getActionName(): string {
        return 'Send Questionnaire';
    }

    public buildPreviewItems(selectionState: FolderSelectionState): BulkOperationPreviewItem[] {
        const items: BulkOperationPreviewItem[] = [];

        const costEstimatorMessage = new BulkOperationCostEstimatorNone(selectionState, this.onAnyCostEstimateChange);

        const state = this.getActionState();

        const questionnaireTimeline = {
            'document': {
                'content': {
                    'questionnaire_name': this.getActionState().questionnaireName,
                    'status': 'assigned'
                }
            },
            document_type: 'questionnaire',
            content: {},
            timelineOrder: 1,
            timelineType: 'questionnaire',
            timelineDate: new Date(),
            lozenge: {
                title: state.lozengeTitle || defaults(state).lozengeTitle,
                description: state.lozengeContent || defaults(state).lozengeContent,
            },
        }

        items.push(new BulkOperationPreviewItem(
            <TimelineContext.Provider value=
                {{
                    currentLanguage: 'en',
                    getMarkedHTML: (markdown) => {
                        if (!markdown) {
                            return { __html: '' };
                        }
                        return { __html: marked(markdown) };
                    },
                    getTranslate: (str) => { TimelineTranslations[str] ? TimelineTranslations[str] : str; },
                    onTimelineElementClick() {
                        console.log('clicked');
                    }
                }}
            >
                <Timeline
                    timeline={[questionnaireTimeline]}
                />
            </TimelineContext.Provider>, costEstimatorMessage));

        if (this.getActionState().sendPush) {
            const costEstimatorPushNotification = new BulkOperationCostEstimatorNone(selectionState, this.onAnyCostEstimateChange);
            items.push(new BulkOperationPreviewItem(<div key={2}>Push notification will be sent.</div>, costEstimatorPushNotification));
        }

        return items;
    }

    public getBulkOperations(): BulkOperationRequestAction<TriggerEngineRequestContent>[] {

        const request: BulkOperationRequestAction<TriggerEngineRequestContent>[] = [];

        request.push(new BulkOperationRequestSendQuestionnaire(getTriggerEngineContent(this.getActionState())));

        if (this.getActionState().sendPush) {
            request.push(new BulkOperationRequestSendPushNotification({ notification: NOTIFICATION_TYPE.MESSAGE }));
        }

        return request;
    }
}
