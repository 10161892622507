import param from 'jquery-param';
import lodash from 'lodash';
import axios from 'axios';
import { store } from 'store';
import uuid from 'uuid';

const isEdge = /Edge\//.test(navigator.userAgent);
if (isEdge) {window.fetch = undefined;} // ensure the polyfill runs

require('es6-promise').polyfill();
require('fetch-ie8');

let serverSideInitialise = false;
let serverSideCallbacks = [];
let serverReq = {};
let serverRes = {};
const stopRequests = false;

class ServerFetch {

    constructor(){
        this.hasSupportForPatchAndPut = !isSpecialIE();
    }

    setPortal (portal) {
        this.portal = portal;
    }

    getPortal () {
        return this.portal;
    }

    getServerSideInitialise () {
        return serverSideInitialise;
    }

    invalidateCache (key) {
        // eslint-disable-next-line no-prototype-builtins
        if (cache.hasOwnProperty(key)) {
            delete(cache[key]);
        }
    }

    getUrl () {
        if (typeof(document) == 'undefined') {
            return serverUrl;
        } else {
            return window.location.pathname;
        }
    }

    clearAll () {
        serverSideCallbacks = [];
        serverSideInitialise = false;
        cache = {};
        serverCache = {};
        openRequests = 0;
        requests = [];
        completeCallbacks = [];
    }

    serverSideStart (res, req) {
        serverUrl = '';
        serverReq = req;
        serverRes = res;
        serverSideInitialise = true;
    }

    serverSideEnd () {
        serverSideInitialise = false;
    }

    addCacheRequest (context, path, data, key, cb, method) {
        let i;

        // do we already have a request with this key?
        for (i = 0; i < requests.length; i++) {
            if (requests[i].key == key && requests[i].useCache === true) {
                requests[i].cb.push(cb);
                return;
            }
        }

        openRequests++;
        requests.push({
            useCache: true,
            context: context,
            method: method || 'GET',
            path: path,
            data: data,
            key: key,
            cb: [cb]
        });

        return;
    }

    addRequest (context, path, data, key, cb, method) {
        openRequests++;

        requests.push({
            useCache: false,
            context: context,
            method: method || 'GET',
            path: path,
            data: data,
            key: key,
            cb: [cb]
        });

        return;
    }

    getKey (key) {
        // eslint-disable-next-line no-prototype-builtins
        if (cache.hasOwnProperty(key)) {
            return cache[key];
        }
    }

    getCookies () {
        return serverReq.cookies;
    }

    getServerKey (key) {
        // eslint-disable-next-line no-prototype-builtins
        if (serverCache.hasOwnProperty(key)) {
            return serverCache[key];
        }
    }

    getRequest (context, url, cb, errorCb, apiVersion = 1) {
        const headers =  { 'Api-Version': apiVersion };

        return this.performRequest(context, url, {}, 'GET', cb, errorCb, headers);
    }

    patchRequest (context, url, data, cb, errorCb) {
        return this.performRequest(context, url, data, 'PATCH', cb, errorCb);
    }

    putRequest (context, url, data, cb, errorCb) {
        return this.performRequest(context, url, data, 'PUT', cb, errorCb);
    }

    postRequest (context, url, data, cb, errorCb, apiVersion = 1, params = {}, headersObj = {}) {
        const headers =  {'Api-Version': apiVersion, ...headersObj};

        return this.performRequest(context, url, data, 'POST', cb, errorCb, headers, params);
    }

    deleteRequest (context, url, data, cb, errorCb) {
        return this.performRequest(context, url, data, 'DELETE', cb, errorCb);
    }

    document (methodType, typeUuid, data, cb) {
        let realMethodType = methodType;

        if (!this.hasSupportForPatchAndPut) {
            if (realMethodType === 'PATCH' || realMethodType === 'PUT') {
                realMethodType = 'POST';
            }
        }

        const bodyOptions = {
            method: realMethodType,
            headers: new Headers({
                'X-HTTP-Method-Override': methodType,
                'Content-Type': 'application/json',
                'X-Portal': this.portal,
                'X-Session-ID': this.getSessionID(),
                'X-Request-Id': uuid.v4(),
                'Cache-control': 'no-cache, no-store, max-age=0',
                'Pragma': 'no-cache',
                'Expires':0
            }),
            credentials: 'same-origin'
        };

        bodyOptions.body = JSON.stringify(data);

        if (typeof(document) === 'undefined') {
            // do server side later
        } else {
            fetch('/api/document/' + typeUuid, bodyOptions).then(function (response) {
                try {
                    response.json().then(cb);
                } catch (e) {
                    // console.log(e);
                    // console.log(data);
                }
            }).catch(function () {
                // console.log('Post Error');
            });
        }
    }

    performRequest (context, url, data, methodType, cb, errorCb, headers = {}, params = {}) {
        let realMethodType = lodash.upperCase(methodType);

        if (!this.hasSupportForPatchAndPut) {
            if (realMethodType === 'PATCH' || realMethodType === 'PUT') {
                realMethodType = 'POST';
            }
        }

        const upperCasedMethodType = lodash.upperCase(methodType);
        const requestTimeout = 60*1000;
        const requestParams = {
            url: url,
            method: realMethodType,
            timeout: requestTimeout,
            headers: Object.assign({
                'Api-Version': 1,
                'X-HTTP-Method-Override': upperCasedMethodType,
                'Content-Type': 'application/json',
                'X-Portal': this.portal,
                'X-Session-ID': this.getSessionID(),
                'X-Request-Id': uuid.v4(),
                'Cache-control': 'no-cache, no-store, max-age=0',
                'Pragma': 'no-cache',
                'Expires':0,
                'X-Requested-With': 'XMLHttpRequest'
            }, headers),
            ...params,
        };

        if (upperCasedMethodType !== 'GET' && upperCasedMethodType !== 'HEAD') {
            requestParams.data = data;
        }

        if (typeof(document) === 'undefined') {
            // do server side later
            return;
        }

        axios(requestParams)
            .then(({ status,data }) => {
                if (cancelledContexts.indexOf(context) !== -1) {
                    return;
                }

                if (lodash.has(data,'redirect')) {
                    window.location.href = data.redirect;
                    return;
                }
                if (lodash.isFunction(cb)) {
                    cb(data, status, null);
                }
            })
            .catch((error) => {
                console.error(`${upperCasedMethodType} error - ${JSON.stringify(error)}`);
                if (lodash.isFunction(errorCb)){
                    errorCb(error);
                } else {
                    cb(error, error && error.response && error.response.status);
                }
            });
    }

    cancelContext(context) {
        cancelledContexts.push(context);
    }

    cancelCurrentRequests(context) {
        // empty
    }

    runRequests () {
        requests.forEach((request, requestIndex) => {
            let postOptions, postRequest;

            // run callbacks based on data from server side cache
            // eslint-disable-next-line no-prototype-builtins
            if (request.useCache && cache.hasOwnProperty(request.key)) {
                request.cb.forEach((requestCb) => {
                    if (serverSideInitialise) {
                        serverSideCallbacks.push([requestCb, cache[request.key]]);
                    } else {
                        requestCb(cache[request.key]);
                    }
                });
                return;
            }

            // run callbacks based on data from server side results
            // eslint-disable-next-line no-prototype-builtins
            if (!request.useCache && serverCache.hasOwnProperty(request.key)) {
                request.cb.forEach(function (requestCb, idx) {
                    if (!serverSideInitialise) {
                        requestCb(serverCache[request.key]);
                    }
                }.bind(this));

                if (typeof(document) !== 'undefined') {
                    delete(serverCache[request.key]);
                }

                return;
            }

            // code to run server side
            if (typeof(document) == 'undefined') {
                // not currently implemented
            } else {
                postOptions = {
                    method: request.method,
                    headers: new Headers({
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'X-Portal': this.portal,
                        'X-Session-ID': this.getSessionID(),
                        'X-Request-Id': uuid.v4(),
                    }),
                    credentials: 'same-origin'
                };

                if (request.method != 'GET' && request.method != 'HEAD') {
                    postOptions.body = param(request.data);
                }

                fetch(request.path, postOptions).then((response) => {
                    let i;

                    if (response.status == 500) {
                        response.json().then((data) => {
                            openRequests--;
                            this.logError(response, data);
                            this.checkCompletedRequests();
                        });
                        return;
                    }

                    if (response.status == 503) {
                        this.websiteDownError();
                        openRequests--;
                        this.checkCompletedRequests();
                        return;
                    }

                    response.json().then((data) => {
                        // eslint-disable-next-line no-prototype-builtins
                        if (data.hasOwnProperty('redirect')) {
                            console.log('REDIRECTING: ' + data.redirect);
                            window.location.href = data.redirect;
                            return;
                        }
                        // eslint-disable-next-line no-prototype-builtins
                        if (data.hasOwnProperty('error')) {
                            if (data.error == '401') {
                                this.handleRedirect(false);
                                return;
                            }
                        }

                        if (request.useCache) {
                            cache[request.key] = data;
                        }

                        for (i = 0; i < request.cb.length; i++) {
                            request.cb[i](data, response.status, null);
                        }

                        openRequests--;
                        this.checkCompletedRequests();
                    });
                });
            }
        });

        requests = [];
    }

    websiteDownError () {
        // @TODO navigate to website is down page
        console.log('Website is currently down');
    }

    logError (res, data) {
        // @TODO navigate to website error page
        console.log('API Server Error', data);
    }

    getServerResponseObject () {
        return serverRes;
    }

    renderFromServerState () {
        if (typeof(document) == 'undefined') {
            this.runRequests();
            if (!serverSideInitialise) {
                this.checkCompletedRequests();
                return true;
            }
        } else {
            this.runRequests();
        }

        return false;
    }

    addOnCompleteCallback (cb) {
        if (openRequests < 1) {
            cb();
        } else {
            completeCallbacks.push(cb);
        }
    }

    checkCompletedRequests () {
        let cb;
        if (openRequests < 1) {
            while (completeCallbacks.length > 0) {
                cb = completeCallbacks.shift();
                cb();
            }
        }
    }

    getData (key) {
        return cache[key];
    }

    getServerData (key) {
        return serverCache[key];
    }

    getJSData () {
        return JSON.stringify(cache);
    }

    getJSServerData () {
        return JSON.stringify(serverCache);
    }

    getSessionID () {
        return getCookieValue('portalSession');
    }

    clearLoginSession () {
        sessionID = false;
    }
}

const serverFetch = new ServerFetch;

let openRequests = 0;

let cache = {};

// for none cached requests that are generated server side
let serverCache = {};

let completeCallbacks = [];

const cancelledContexts = [];
let requests = [];

let serverUrl = '';

if (typeof(getServerFetchData) !== 'undefined') {
    // eslint-disable-next-line no-undef
    cache = getServerFetchData();
}

if (typeof(getServerCacheData) !== 'undefined') {
    // eslint-disable-next-line no-undef
    serverCache = getServerCacheData();
}

export default serverFetch;

function getCookieValue (a) {
    let cookieList = {};

    if (typeof (document) == 'undefined') {
        cookieList = serverFetch.getCookies();

        // eslint-disable-next-line no-prototype-builtins
        if (cookieList.hasOwnProperty(a)) {
            return cookieList[a];
        }

        return undefined;
    }

    const b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
    return b ? decodeURIComponent(b.pop()) : '';
}

let sessionID = false;

if (typeof (window) !== 'undefined' && typeof(state) !== 'undefined') {
    setInterval(() => {
        const isAuthenticated = store.getState().auth.get('isAuthenticated');
        if (isAuthenticated) {
            serverFetch.getRequest(serverFetch,'/session-code', (response) => {
                if (!sessionID) {
                    sessionID = response.code;
                    return;
                }

                if (sessionID !== response.code || response.code == '') {
                    window.location.reload();
                }
            });
        }
    }, 5000);
}

function isSpecialIE() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');
    if (msie > 0) {
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10) <= 9;
    }
    return false;
}
