/* eslint-disable @typescript-eslint/camelcase */

import React, { useState } from 'react';
import { FolderSelectionState } from '../dataSource/FolderSelectionState';
import BulkOperationConfigurationComponent from './BulkOperationConfigurationComponent';
import { BulkOperationPreviewBuilder, BulkOperationPreviewItem } from './BulkOperationPreviewBuilder';
import { BulkOperationRequestAction, BulkOperationRequestAddPifu } from 'models/BulkOperationRequestAction';
import { TriggerEngineRequestContent } from 'models/TriggerEngineRequestContent';
import { AddPifuState, validate, normalise, getTriggerEngineContent } from '../user/Pifu/validator';
import { usePIFUOptionList } from 'features/patient/pifu/pifu.hooks';
import { ComponentLoadingWrapper } from 'features/patient/patient-dashboard/component-loading-wrapper/ComponentLoadingWrapper';
import { BulkOperationCostEstimatorNone } from './BulkOperationCostEstimator';
import ErrorView from 'components/errorbox';

const initialFormState: AddPifuState = {
    name: '',
    context: '',
    description: '',
    actionName: ''
};

export class BulkOperationAddPifuConfigurator extends BulkOperationConfigurationComponent<AddPifuState> {
    public validate: () => boolean;

    constructor(pifuActionName) {
        super();
        initialFormState.actionName = pifuActionName;
    }

    public getActionName(): string {
        return initialFormState.actionName;
    }

    public getInitialFormState(): AddPifuState {
        return initialFormState;
    }

    public SetupComponent(): JSX.Element {
        const [currentErrors, onErrors] = useState<string[]>([]);
        const { isLoading, list } = usePIFUOptionList();
        const [selectedOptionId, setSelectedOptionId] = useState<string>();
        const { currentFormState, onFormStateChange } = this.useFormState();

        this.validate = () => validate(this.getFormState(), onErrors);

        return (
            <div>
                <ErrorView errors={currentErrors} />
                <ComponentLoadingWrapper
                    loading={isLoading}
                    dataExists={list.length !== 0}>   
                </ComponentLoadingWrapper>
                {list.map((option, index) => {
                    const underline = index !== list.length -1;
                    return (
                        <div key={option.id} className="pifu-form-option" onClick={() => {
                            onFormStateChange({
                                name: option.live.label_name,
                                context: option.live.label_context,
                                description: option.live.description
                            });
                            setSelectedOptionId(option.id);
                        }}>
                            <div className="pifu-form-option__input-wrapper">
                                <input
                                    className="pifu-form-option__input"
                                    type="radio"
                                    checked={selectedOptionId === option.id}
                                    onChange={() => undefined}
                                />
                            </div>
                            <div className="pifu-form-option__info-wrapper">
                                <div className="pifu-form-option__title">{option.live?.name}</div>
                                <div className="pifu-form-option__description">
                                    {option.live?.description}
                                </div>
                                {underline && <div className="pifu-form-option__underline" />}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }

    public getPreviewBuilder(selectionState: FolderSelectionState): BulkOperationPreviewBuilder<AddPifuState> {

        return new BulkOperationAddPifuPreviewBuilder(selectionState, normalise(this.getFormState()));
    }
}

export class BulkOperationAddPifuPreviewBuilder extends BulkOperationPreviewBuilder<AddPifuState> {
    public getActionName(): string {
        return initialFormState.actionName;
    }

    public buildPreviewItems(selectionState: FolderSelectionState): BulkOperationPreviewItem[] {
        const items: BulkOperationPreviewItem[] = [];
        
        const costEstimatorMessage = new BulkOperationCostEstimatorNone(selectionState, this.onAnyCostEstimateChange);
        
        const state: AddPifuState = this.getActionState();

        items.push(new BulkOperationPreviewItem(
            <div className="pifu-form-option">
                <div className="pifu-form-option__info-wrapper">
                    <div className="pifu-form-option__title">{state.name}</div>
                    <div className="pifu-form-option__description">
                        <p>{state.description}</p>
                    </div>
                    <div className="pifu-form-option__underline" />
                </div>
            </div>
            , costEstimatorMessage
        ));
        return items;
    }

    public getBulkOperations(): BulkOperationRequestAction<TriggerEngineRequestContent>[] {
        const request: BulkOperationRequestAction<TriggerEngineRequestContent>[] = [];

        request.push(new BulkOperationRequestAddPifu(getTriggerEngineContent(this.getActionState())));

        return request;
    }
}
