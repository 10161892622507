/* eslint-disable indent */
import React, { Fragment, useState } from 'react';
// components
import Modal from 'common/ui/modal';
import QuestionnaireContainerComponent from 'features/patient/questionnaire/QuestionnaireContainerComponent';
import AppointmentContainer from 'features/patient/appointments/AppointmentContainer';
// hooks
import { useAdvancedSearchHelper } from 'features/patients/advanced-search/advanced-search-helper-context';
// interfaces
import { AdvancedSearchQueryResult } from 'models/AdvancedSearchDefinition';
import { ContainerProps } from 'features/patient/questionnaire/QuestionnaireContainerComponent';

type AdvancedSearchResultProps = {
    result: AdvancedSearchQueryResult;
};

const getAdvancedSearchTableViewButtonData = (
    result,
    className: string,
    key
) => {

    const cellClassName = className ?? '';

    return {
        key: key,
        cellClassName: cellClassName,
        data: <AdvancedSearchTableViewButton result={result}/>
    };
};

const AdvancedSearchTableViewButton = ({
   result
}: AdvancedSearchResultProps) => {
    const [showModal, setShowModal] = useState<boolean>(false);

    const { refresh } = useAdvancedSearchHelper();

    const checkDocumentType = (type): JSX.Element => {
        const questionnaireProps: ContainerProps = {
            allocateQuestionnaireLink: `/clinical_portal/folder/${result.folderId}/patient/questionnaires/create`,
            allocateSimpleQuestionnaireLink:`/clinical_portal/folder/${result.folderId}/patient/questionnaires/create-simple`,
            backLink: `/clinical_portal/folder/${result.folderId}/patient/questionnaires/create`,
            location: {
                query: {
                    questionnaire: null,
                },
                search: '?questionnaire',
                state: undefined,
                action: 'PUSH',
                hash: '',
                key: '',
                pathname: '',
            },
            questionnaireId: result.uuid,
            teamId: result.team_id,
            folderId: result.folderId,
            inModal: true,
        };
        switch (type) {
            case 'appointment':
                return (
                    <AppointmentContainer
                        uuid={result.uuid}
                        folderId={result.folderId}
                        onSubmit={() => {
                            setShowModal(false);
                            refresh();
                        }}
                    />
                );
            case 'questionnaireResponse':
                return (
                    <QuestionnaireContainerComponent {...questionnaireProps} />
                );
        }
    };

    return (
        <Fragment>
            <button
                onClick={() => {
                    setShowModal(!showModal);
                }}
                className="btn btn-xs btn-default view"
            >
                View
            </button>
            {showModal && (
                <Modal
                    onClose={() => setShowModal(false)}
                    size="lg"
                    title={
                        result.documentType === 'appointment'
                            ? 'View Appointment'
                            : 'View Questionnaire'
                    }
                >
                    {checkDocumentType(result.documentType)}
                </Modal>
            )}
        </Fragment>
    );
};

export default getAdvancedSearchTableViewButtonData;
