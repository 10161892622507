/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import { FolderSelectionState } from '../../dataSource/FolderSelectionState';
import { SendEmailState, validate as validateEmail, normalise as normaliseEmail } from 'components/user/email/create/validator';
import BulkOperationConfigurationComponent from '../BulkOperationConfigurationComponent';
import { BulkOperationPreviewBuilder, BulkOperationPreviewItem } from '../BulkOperationPreviewBuilder';
import { BulkOperationCostEstimatorNone, BulkOperationCostEstimatorUnknown } from '../BulkOperationCostEstimator';
import { BulkOperationRequestAction, BulkOperationRequestAutoCommunication } from 'models/BulkOperationRequestAction';
import { TriggerEngineRequestContent } from 'models/TriggerEngineRequestContent';
import AutoCommunicationAccordion from './AutoCommunicationAccordion';
import { SendSmsState, validate as validateSms, normalise as normaliseSms } from 'components/user/sms/create/validator';
import { SendLetterState } from 'components/user/letters/letters.interface';
import { validate as validateLetter, normalise as normaliseLetter } from 'components/user/letters/create/validator';
import { LetterPdfPreview } from 'components/user/letters/create/pdfPreview';
import SmsPreview from 'components/user/sms/create/preview';
import EmailPreview from 'components/user/email/create/preview';
import ErrorView from 'components/errorbox';
import { lettersService } from 'services/letters.service';
import { predefinedSMSService } from 'services/predefined-sms.service';
import _ from 'services/i18n';
import {CompositionService} from "services/composition.service";

export type AutoCommunicationState = {
    emailIfRegistered: {
        enabled: boolean;
        data: SendEmailState;
    };
    emailIfUnregistered: {
        enabled: boolean;
        data: SendEmailState;
    };
    sms: {
        enabled: boolean;
        data: SendSmsState;
    };
    letter: {
        enabled: boolean;
        data: SendLetterState;
    };
}

export type AutoCommunicationErrors = {
    general: string[];
    emailIfRegistered: string[];
    emailIfUnregistered: string[];
    sms: string[];
    letter: string[];
}

const initialFormState: AutoCommunicationState = {
    emailIfRegistered: { enabled: true, data: { subject: '', htmlContent: '', plainTextContent: '' } },
    emailIfUnregistered: { enabled: true, data: { subject: '', htmlContent: '', plainTextContent: '' } },
    sms: { enabled: true, data: { message: '' } },
    letter: { enabled: true, data: { htmlContent: '' } }
};

const initialErrors: AutoCommunicationErrors = {
    general: [],
    emailIfRegistered: [],
    emailIfUnregistered: [],
    sms: [],
    letter: []
};

export class BulkOperationAutoCommunicationConfigurator extends BulkOperationConfigurationComponent<AutoCommunicationState>
{
    private readonly smsTemplateService: CompositionService;

    public validate: () => boolean;
    
    constructor(smsTemplateServiceVal: CompositionService = predefinedSMSService) {
        super();
        this.smsTemplateService = smsTemplateServiceVal;
    }
    
    public getActionName(): string {
        return _`Auto Communication`;
    }
    
    public getInitialFormState(): AutoCommunicationState {
        return initialFormState;
    }
    
    public SetupComponent(): JSX.Element {
        const [currentErrors, onErrors] = useState(initialErrors);
        
        const { currentFormState, onFormStateChange } = this.useFormState();
        
        this.validate = () => {

            const emailIfRegisteredEnabled = this.getFormState().emailIfRegistered.enabled;
            const emailIfUnregisteredEnabled = this.getFormState().emailIfUnregistered.enabled;
            const smsEnabled = this.getFormState().sms.enabled;
            const letterEnabled = this.getFormState().letter.enabled;

            let validationErrors = initialErrors;

            if (!emailIfRegisteredEnabled && !emailIfUnregisteredEnabled && !smsEnabled && !letterEnabled) {
                const error = _`Must have at least one form of communication enabled.`;
                validationErrors = { ...validationErrors, general: [...validationErrors.general, error] };
            }

            if (emailIfRegisteredEnabled) {
                validateEmail(this.getFormState().emailIfRegistered.data, (errors) => {
                    validationErrors = { ...validationErrors, emailIfRegistered: errors };
                });
            }
            if (emailIfUnregisteredEnabled) {
                validateEmail(this.getFormState().emailIfUnregistered.data, (errors) => {
                    validationErrors = { ...validationErrors, emailIfUnregistered: errors };
                });
            }
            if (smsEnabled) {
                validateSms(this.getFormState().sms.data, (errors) => {
                    validationErrors = { ...validationErrors, sms: errors };
                });
            }
            if (letterEnabled) {
                validateLetter(this.getFormState().letter.data, (errors) => {
                    validationErrors = { ...validationErrors, letter: errors };
                });
            }

            onErrors(validationErrors);
            
            return validationErrors.general.length === 0 && validationErrors.emailIfRegistered.length === 0 &&
                validationErrors.emailIfUnregistered.length === 0 &&
                validationErrors.sms.length === 0 && validationErrors.letter.length === 0;
        };
        
        return (
            <>
                <ErrorView errors={currentErrors.general}/>
                <AutoCommunicationAccordion 
                    formState={currentFormState} 
                    onFormStateChange={onFormStateChange}
                    errors={currentErrors} 
                    onErrors={onErrors}
                    smsTemplateService={this.smsTemplateService}
                />
            </>
        );
    }

    public getPreviewBuilder(selectionState: FolderSelectionState): BulkOperationPreviewBuilder<AutoCommunicationState> {
        return new BulkOperationAutoCommunicationPreviewBuilder(selectionState, this.getFormState());
    }
}

export class BulkOperationAutoCommunicationPreviewBuilder extends BulkOperationPreviewBuilder<AutoCommunicationState>
{
    public getActionName(): string {
        return _`Auto Communication`;
    }

    public buildPreviewItems(selectionState: FolderSelectionState): BulkOperationPreviewItem[] {
        const items: BulkOperationPreviewItem[] = [];

        const noneCostEstimatorMessage = new BulkOperationCostEstimatorNone(selectionState, this.onAnyCostEstimateChange);
        const unknownCostEstimatorMessage = new BulkOperationCostEstimatorUnknown(selectionState, this.onAnyCostEstimateChange);
        
        {this.getActionState().emailIfRegistered.enabled && 
            items.push(new BulkOperationPreviewItem(
                <>
                    <h4>{_`Email - Registered Patient`}</h4>
                    <EmailPreview 
                        subject={this.getActionState().emailIfRegistered.data.subject} 
                        htmlContent={this.getActionState().emailIfRegistered.data.htmlContent}
                    />
                </>
                , noneCostEstimatorMessage));
        }
        {this.getActionState().emailIfUnregistered.enabled && 
            items.push(new BulkOperationPreviewItem(    
                <>
                    <h4>{_`Email - Non Registered Patient`}</h4>
                    <EmailPreview 
                        subject={this.getActionState().emailIfUnregistered.data.subject} 
                        htmlContent={this.getActionState().emailIfUnregistered.data.htmlContent}
                    />
                </>, noneCostEstimatorMessage));
        }
        {this.getActionState().sms.enabled && 
            items.push(new BulkOperationPreviewItem(
                <SmsPreview message={this.getActionState().sms.data.message}/>
                , unknownCostEstimatorMessage));
        }
        {this.getActionState().letter.enabled && 
            items.push(new BulkOperationPreviewItem(
                <LetterPdfPreview
                    stationery={this.getActionState().letter.data.stationery}
                    letter={this.getActionState().letter.data.htmlContent}
                    letterService={lettersService}
                />
                , unknownCostEstimatorMessage));
        }

        return items;
    }

    public getBulkOperations(): BulkOperationRequestAction<TriggerEngineRequestContent>[] {
        const request: BulkOperationRequestAction<TriggerEngineRequestContent>[] = [];

        request.push(new BulkOperationRequestAutoCommunication(this.buildTriggerEngineRequestContent()));

        return request;
    }

    buildTriggerEngineRequestContent() {
        const content = {};

        if (this.getActionState().emailIfRegistered.enabled) {
            content['emailIfRegistered'] = normaliseEmail(this.getActionState().emailIfRegistered.data);
        }
        if (this.getActionState().emailIfUnregistered.enabled) {
            content['emailIfUnregistered'] = normaliseEmail(this.getActionState().emailIfUnregistered.data);
        }
        if (this.getActionState().sms.enabled) {
            content['sms'] = normaliseSms(this.getActionState().sms.data);
        }
        if (this.getActionState().letter.enabled) {

            const { htmlContent, stationery, template } = normaliseLetter(this.getActionState().letter.data);

            const templateName = template ? template.yamlFilename : '';

            content['letter'] = {
                deliveryType: 'Standard',
                productType: 'A4Letter',
                duplex: true,
                mono: false,
                pages: [
                    {
                        page: 1,
                        header: stationery.header.htmlFilename,
                        body: htmlContent,
                        footer: stationery.footer.htmlFilename,
                    },
                ],
                templateName: templateName
            };
        }

        return content;
    }
}
