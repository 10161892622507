/* eslint-disable @typescript-eslint/camelcase */

import React, { useState } from 'react';
import { FolderSelectionState } from '../dataSource/FolderSelectionState';
import BulkOperationConfigurationComponent from './BulkOperationConfigurationComponent';
import { BulkOperationPreviewBuilder, BulkOperationPreviewItem } from './BulkOperationPreviewBuilder';
import { BulkOperationRequestAction, BulkOperationRequestSendSimpleQuestionnaire } from 'models/BulkOperationRequestAction';
import { TriggerEngineRequestContent } from 'models/TriggerEngineRequestContent';
import { SendSimpleQuestionnaireState, validate, normalise, getTriggerEngineContent } from '../user/simple-questionnaire/validator';
import { SimpleQuestionnaireCreateForm } from '../user/simple-questionnaire/form';
import { BulkOperationCostEstimatorNone } from './BulkOperationCostEstimator';
import { Timeline, TimelineContext, TimelineTranslations } from 'msk-timeline';
import marked from 'marked';

const initialFormState: SendSimpleQuestionnaireState = {
    location: '',
    id: '',
    lozengeTitle: '',
    lozengeContent: ''
};

export class BulkOperationSimpleQuestionnaireConfigurator extends BulkOperationConfigurationComponent<SendSimpleQuestionnaireState> {
    public validate: () => boolean;

    constructor() {
        super();
    }

    public getActionName(): string {
        return 'Send Simple Questionnaire';
    }

    public getInitialFormState(): SendSimpleQuestionnaireState {
        return initialFormState;
    }

    public SetupComponent(): JSX.Element {
        const [currentErrors, onErrors] = useState<string[]>([]);
        const { currentFormState, onFormStateChange } = this.useFormState();

        this.validate = () => validate(this.getFormState(), onErrors);

        return (
            <SimpleQuestionnaireCreateForm
                form={currentFormState}
                onChange={(c) => onFormStateChange({ ...currentFormState, ...c })}
                errors={currentErrors}
            />
        );
    }

    public getPreviewBuilder(selectionState: FolderSelectionState): BulkOperationPreviewBuilder<SendSimpleQuestionnaireState> {
        return new BulkOperationSimpleQuestionnairePreviewBuilder(selectionState, normalise(this.getFormState()));
    }
}

export class BulkOperationSimpleQuestionnairePreviewBuilder extends BulkOperationPreviewBuilder<SendSimpleQuestionnaireState> {
    public getActionName(): string {
        return 'Send Simple Questionnaire';
    }

    public buildPreviewItems(selectionState: FolderSelectionState): BulkOperationPreviewItem[] {
        const items: BulkOperationPreviewItem[] = [];

        const costEstimatorMessage = new BulkOperationCostEstimatorNone(selectionState, this.onAnyCostEstimateChange);

        const state: SendSimpleQuestionnaireState = this.getActionState();

        const questionnaireTimeline = {
            'document': {
                'content': {
                    'questionnaire_name': state.id,
                    'status': 'assigned'
                }
            },
            document_type: 'questionnaire',
            content: {},
            timelineOrder: 1,
            timelineType: 'questionnaire',
            timelineDate: new Date(),
            lozenge: {
                title: state.lozengeTitle,
                description: state.lozengeContent,
            },
        };

        items.push(new BulkOperationPreviewItem(
            <TimelineContext.Provider value=
                {{
                    currentLanguage: 'en',
                    getMarkedHTML: (markdown) => {
                        if (!markdown) {
                            return { __html: '' };
                        }
                        return { __html: marked(markdown) };
                    },
                    getTranslate: (str) => { TimelineTranslations[str] ? TimelineTranslations[str] : str; },
                    onTimelineElementClick() {
                        // eslint-disable-next-line no-console
                        console.log('clicked');
                    }
                }}
            >
                <Timeline
                    timeline={[questionnaireTimeline]}
                />
            </TimelineContext.Provider>, costEstimatorMessage));

        return items;
    }

    public getBulkOperations(): BulkOperationRequestAction<TriggerEngineRequestContent>[] {
        const request: BulkOperationRequestAction<TriggerEngineRequestContent>[] = [];

        request.push(new BulkOperationRequestSendSimpleQuestionnaire(getTriggerEngineContent(this.getActionState())));

        return request;
    }
}
