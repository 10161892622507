/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import { FolderSelectionState } from '../dataSource/FolderSelectionState';
import BulkOperationConfigurationComponent from './BulkOperationConfigurationComponent';
import { BulkOperationPreviewBuilder, BulkOperationPreviewItem } from './BulkOperationPreviewBuilder';
import {
    BulkOperationAssignPathwayEvent,
    BulkOperationRequestAction
} from 'models/BulkOperationRequestAction';
import { TriggerEngineRequestContent } from 'models/TriggerEngineRequestContent';
import {CostEstimateValue, UnknownCostEstimateValue} from "components/bulk-operations/CostEstimateValue";

const initialFormState = {  };

export class BulkOperationAssignPathwayEventConfigurator extends BulkOperationConfigurationComponent<any>
{

    public validate: () => boolean;

    constructor()
    {
        super();
    }

    public getActionName(): string
    {
        return 'Send Invitation';
    }

    public getInitialFormState(): any
    {
        return initialFormState;
    }

    public SetupComponent(): JSX.Element
    {
        this.validate = () => true;

        return (<></>);
    }

    public getPreviewBuilder(selectionState: FolderSelectionState): BulkOperationPreviewBuilder<any>
    {
        return new BulkOperationAssignPathwayEventPreviewBuilder(selectionState, this.getFormState());
    }
}

export class BulkOperationAssignPathwayEventPreviewBuilder extends BulkOperationPreviewBuilder<any>
{
    public getActionName(): string
    {
        return 'Send Invitation';
    }

    public buildPreviewItems(selectionState: FolderSelectionState): BulkOperationPreviewItem[]
    {
        return [];
    }

    public getCostEstimates = (): CostEstimateValue[] => {
        return [UnknownCostEstimateValue];
    }

    public getBulkOperations(): BulkOperationRequestAction<TriggerEngineRequestContent>[]
    {
        const request: BulkOperationRequestAction<TriggerEngineRequestContent>[] = [];

        request.push(new BulkOperationAssignPathwayEvent({
            pathwayEvent: "start-default-onboarding-pathway"
        }));

        return request;
    }
}
