import React, { useState } from 'react';
// bulk operation core components
import BulkOperationConfigurationComponent from './BulkOperationConfigurationComponent';
import {
    BulkOperationPreviewBuilder,
    BulkOperationPreviewItem,
} from './BulkOperationPreviewBuilder';
import {
    BulkOperationRequestAction,
    BulkOperationRequestSendLetter,
} from 'models/BulkOperationRequestAction';
import { BulkOperationCostEstimatorUnknown } from './BulkOperationCostEstimator';
// components
import { LetterCreateForm } from '../user/letters/create/form';
import { LetterPdfPreview } from '../user/letters/create/pdfPreview';
// helpers
import { validate, normalise } from '../user/letters/create/validator';
import { lettersService } from 'services/letters.service';
// interfaces
import { TriggerEngineRequestContent } from 'models/TriggerEngineRequestContent';
import {
    SendLetterState,
} from '../user/letters/letters.interface';
import { FolderSelectionState } from '../dataSource/FolderSelectionState';

const initialFormState: SendLetterState = {
    htmlContent: '',
};

export class BulkOperationSendLetterConfigurator extends BulkOperationConfigurationComponent<SendLetterState> {
    public validate: () => boolean;

    constructor() {
        super();
    }

    public getActionName(): string {
        return 'Send Letter';
    }

    public getInitialFormState(): SendLetterState {
        return initialFormState;
    }

    public SetupComponent(): JSX.Element {
        const [currentErrors, onErrors] = useState<string[]>([]);
        const { currentFormState, onFormStateChange } = this.useFormState();

        this.validate = () => validate(this.getFormState(), onErrors);

        return (
            <LetterCreateForm
                id='bo-create-letter'
                errors={currentErrors}
                onChange={(partialState) =>
                    onFormStateChange({ ...currentFormState, ...partialState })
                }
                form={currentFormState}
                onSubmit={(f) => f.preventDefault()}
                hideSubmit={true}
                onErrors={onErrors}
            />
        );
    }

    public getPreviewBuilder(
        selectionState: FolderSelectionState,
    ): BulkOperationPreviewBuilder<SendLetterState> {
        return new BulkOperationSendLetterPreviewBuilder(
            selectionState,
            normalise(this.getFormState()),
        );
    }
}

export class BulkOperationSendLetterPreviewBuilder extends BulkOperationPreviewBuilder<SendLetterState> {
    public getActionName(): string {
        return 'Send Letter';
    }

    public buildPreviewItems(
        selectionState: FolderSelectionState,
    ): BulkOperationPreviewItem[] {
        const items: BulkOperationPreviewItem[] = [];

        const { stationery, htmlContent } = this.getActionState();

        const costEstimatorMessage = new BulkOperationCostEstimatorUnknown(
            selectionState,
            this.onAnyCostEstimateChange,
        );

        items.push(
            new BulkOperationPreviewItem(
                (
                    <LetterPdfPreview
                        stationery={stationery}
                        letter={htmlContent}
                        letterService={lettersService}
                    />
                ),
                costEstimatorMessage,
            ),
        );

        return items;
    }

    public getBulkOperations(): BulkOperationRequestAction<TriggerEngineRequestContent>[] {
        const { htmlContent, stationery, template } = this.getActionState();

        const templateName = template ? template.yamlFilename : '';
        const request: BulkOperationRequestAction<TriggerEngineRequestContent>[] = [];

        request.push(
            new BulkOperationRequestSendLetter({
                deliveryType: 'Standard',
                productType: 'A4Letter',
                duplex: true,
                mono: false,
                pages: [
                    {
                        page: 1,
                        header: stationery.header.htmlFilename,
                        body: htmlContent,
                        footer: stationery.footer.htmlFilename,
                    },
                ],
                templateName: templateName
            }),
        );

        return request;
    }
}
