import React, { useState } from 'react';
import { FolderSelectionState } from '../../dataSource/FolderSelectionState';
import BulkOperationConfigurationComponent from '../BulkOperationConfigurationComponent';
import { BulkOperationPreviewBuilder, BulkOperationPreviewItem } from '../BulkOperationPreviewBuilder';
import { BulkOperationCostEstimatorNone } from '../BulkOperationCostEstimator';
import ResourceCollectionSelector from './ResourceCollectionSelector';
import { TriggerEngineRequestContent } from 'models/TriggerEngineRequestContent';
import { BulkOperationRequestAction, BulkOperationRequestSendPushNotification, BulkOperationSendResourceCollection } from 'models/BulkOperationRequestAction';
import _ from 'services/i18n';
import ErrorView from 'components/errorbox';
import { ResourceCategory } from 'models/ResourceCategory';
import { Composition } from 'models/Composition';
import { Timeline, TimelineContext, TimelineTranslations } from 'msk-timeline';
import marked from 'marked';
import { NOTIFICATION_TYPE } from 'services/push-notifications.service';

export type SendResourceCollectionState = {
    selectedCollections: Composition<ResourceCategory>[];
    notifyTimeline: boolean;
    lozengeTitle: string;
    lozengeContent: string;
    sendPush: boolean;
}

const initialFormState: SendResourceCollectionState = {
    selectedCollections: [],
    notifyTimeline: false,
    lozengeTitle: '',
    lozengeContent: '',
    sendPush: false
};


export class BulkOperationSendResourceCollectionConfigurator extends BulkOperationConfigurationComponent<SendResourceCollectionState> {
    public validate: () => boolean;
    
    constructor() {
        super();
    }
    
    public getActionName(): string {
        return _`Send Resource Collection`;
    }
    
    public getInitialFormState(): SendResourceCollectionState {
        return initialFormState;
    }
    
    public SetupComponent(): JSX.Element {
        const [currentErrors, onErrors] = useState([]);
        
        const { currentFormState, onFormStateChange } = this.useFormState();
        
        this.validate = () => {

            const errors = [];

            if (currentFormState.selectedCollections.length <= 0) {
                errors.push(_`Must select at least one collection`);
            }

            if(currentFormState.notifyTimeline) {
                if(!currentFormState.lozengeTitle.trim()) {
                    errors.push(_`Lozenge must have a title`);
                }

                if(!currentFormState.lozengeContent.trim()) {
                    errors.push(_`Lozenge must have content`);
                }
            }

            if (errors.length > 0) {
                onErrors(errors);
                return false;
            }

            return true;
        };
        
        return (
            <>
                <ErrorView errors={currentErrors} />
                <ResourceCollectionSelector formState={currentFormState} setFormState={onFormStateChange}/>
            </>
        );
    }

    public getPreviewBuilder(selectionState: FolderSelectionState): BulkOperationPreviewBuilder<SendResourceCollectionState> {
        return new BulkOperationSendResourceCollectionPreviewBuilder(selectionState, this.getFormState());
    }
}

export class BulkOperationSendResourceCollectionPreviewBuilder extends BulkOperationPreviewBuilder<SendResourceCollectionState>
{
    public getActionName(): string {
        return _`Send Resource Collection`;
    }

    public buildPreviewItems(selectionState: FolderSelectionState): BulkOperationPreviewItem[] {
        const items: BulkOperationPreviewItem[] = [];

        const noneCostEstimatorMessage = new BulkOperationCostEstimatorNone(selectionState, this.onAnyCostEstimateChange);

        const timelineMessage = {
            'document_type': 'message',
            content: {},
            timelineOrder: 1,
            timelineType: 'message',
            timelineDate: new Date(),
            lozenge: {
                title: this.getActionState().lozengeTitle,
                description: this.getActionState().lozengeContent,
            }
        };

        items.push(new BulkOperationPreviewItem(
            <>
                <h4>{_`You have selected to send the following resource collection(s) to the selected patients:`}</h4>
                <p className="collection-list-preview">{this.getActionState().selectedCollections.map((collection) => collection.content.name).join(', ')}</p>
                {this.getActionState().notifyTimeline && (
                    <TimelineContext.Provider value={{
                        currentLanguage: 'en',
                        getMarkedHTML: (markdown) => {
                            if (!markdown) {
                                return { __html: '' };
                            }
                            return { __html: marked(markdown) };
                        },
                        getTranslate: (str) => _`${TimelineTranslations[str] ? TimelineTranslations[str] : str}`,
                        onTimelineElementClick() {
                            console.log('clicked');
                        }
                    }}>
                        <Timeline
                            timeline={[timelineMessage]}
                            showPopup={false}
                        />
                    </TimelineContext.Provider>
                )}
            </>
            , noneCostEstimatorMessage));
        return items;
    }

    public getBulkOperations(): BulkOperationRequestAction<TriggerEngineRequestContent>[] {
        const request: BulkOperationRequestAction<TriggerEngineRequestContent>[] = [];

        const requestContent = {
            resourceCategoryUuids: this.getActionState().selectedCollections.map((collection) => collection.uuid),
        };

        if (this.getActionState().notifyTimeline) {
            requestContent['message'] = {
                title: this.getActionState().lozengeTitle,
                content: this.getActionState().lozengeContent,
                type: 'clinician'
            };
        }

        request.push(new BulkOperationSendResourceCollection(requestContent));

        if (this.getActionState().sendPush) {
            request.push(new BulkOperationRequestSendPushNotification({ notification: NOTIFICATION_TYPE.RESOURCE }));
        }

        return request;
    }
}
