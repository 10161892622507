/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import { FolderSelectionState } from '../dataSource/FolderSelectionState';
import { ResourceCreateForm } from 'components/user/resource/create/form';
import { CreateResourceState, normalise, validate, getTriggerEngineContent } from 'components/user/resource/create/validator';
import BulkOperationConfigurationComponent from './BulkOperationConfigurationComponent';
import { BulkOperationPreviewBuilder, BulkOperationPreviewItem } from './BulkOperationPreviewBuilder';
import { BulkOperationRequestAction, BulkOperationRequestCreateResource, BulkOperationRequestSendPushNotification } from 'models/BulkOperationRequestAction';
import { TriggerEngineRequestContent } from 'models/TriggerEngineRequestContent';
import { CompositionService } from 'services/composition.service';
import { resourceCategoryService } from 'services/resourceCategory.service';
import { useEffect } from 'react';
import { Timeline, TimelineContext, TimelineTranslations } from 'msk-timeline';
import marked from 'marked';
import { BulkOperationCostEstimatorNone } from './BulkOperationCostEstimator';
import { NOTIFICATION_TYPE } from 'services/push-notifications.service';

const initialFormState: CreateResourceState = {
    type: '',
    label: '',
    url: '',
    phone: '',
    html: '',
    description: '',
    mediaFile: null,
    mediaFileUuid: '',
    category: [],
    lozengeTitle: '',
    lozengeText: '',
    sendPush: false,
    notifyTimeline: false
};

export class BulkOperationCreateResourceConfigurator extends BulkOperationConfigurationComponent<CreateResourceState> {

    private readonly resourceCategoryService: CompositionService;

    public validate: () => boolean;

    constructor(resourceCategoryServiceVal: CompositionService = resourceCategoryService) {
        super();
        this.resourceCategoryService = resourceCategoryServiceVal;
    }

    public getActionName(): string {
        return 'Assign Personalised Resource';
    }

    public getInitialFormState(): CreateResourceState {
        return initialFormState;
    }

    private getCategories(): Promise<any[]> {
        return this.resourceCategoryService.list({ folderId: 'team' })
            .then(r => r?.message?.results || []);
    }

    public SetupComponent(): JSX.Element {
        const [currentErrors, onErrors] = useState<string[]>([]);

        const { currentFormState, onFormStateChange } = this.useFormState();

        this.validate = () => validate(this.getFormState(), onErrors);

        return (
            <ResourceCreateForm
                errors={currentErrors}
                onChange={(c) => onFormStateChange({ ...currentFormState, ...c })}
                form={currentFormState}
                onSubmit={(f) => f.preventDefault()}
                hideSubmit={true}
                onShowMediaResource={null}/>
        );
    }

    public getPreviewBuilder(selectionState: FolderSelectionState): BulkOperationPreviewBuilder<CreateResourceState> {
        return new BulkOperationCreateResourcePreviewBuilder(selectionState, normalise(this.getFormState()));
    }
}

export class BulkOperationCreateResourcePreviewBuilder extends BulkOperationPreviewBuilder<CreateResourceState> {
    public getActionName(): string {
        return 'Assign Personalised Resource';
    }

    public buildPreviewItems(selectionState: FolderSelectionState): BulkOperationPreviewItem[] {
        const items: BulkOperationPreviewItem[] = [];

        const costEstimatorMessage = new BulkOperationCostEstimatorNone(selectionState, this.onAnyCostEstimateChange);

        const messageTimeline = {
            document_type: 'message',
            content: {},
            timelineOrder: 1,
            timelineType: 'message',
            timelineDate: new Date(),
            lozenge: {
                title: this.getActionState().lozengeTitle || 'Information to help you',
                description: this.getActionState().lozengeText || 'Your clinician has suggested some information that may be helpful to you. Tap here to read it'
            },
        };

        items.push(new BulkOperationPreviewItem(
            <TimelineContext.Provider value=
                {{
                    currentLanguage: 'en',
                    getMarkedHTML: (markdown) => {
                        if (!markdown) {
                            return { __html: '' };
                        }
                        return { __html: marked(markdown) };
                    },
                    getTranslate: (str) => { TimelineTranslations[str] ? TimelineTranslations[str] : str; },
                    onTimelineElementClick() {
                        console.log('clicked');
                    }
                }}
            >
                <Timeline
                    timeline={[messageTimeline]}
                    showPopup={false}
                />
            </TimelineContext.Provider>, costEstimatorMessage));

        if (this.getActionState().sendPush) {
            const costEstimatorPushNotification = new BulkOperationCostEstimatorNone(selectionState, this.onAnyCostEstimateChange);
            items.push(new BulkOperationPreviewItem(<div className='push-notification-preview' key={2}>Push notification will be sent.</div>, costEstimatorPushNotification));
        }

        return items;
    }

    public getBulkOperations(): BulkOperationRequestAction<TriggerEngineRequestContent>[] {
        const request: BulkOperationRequestAction<TriggerEngineRequestContent>[] = [];
        const state: CreateResourceState = this.getActionState();

        request.push(new BulkOperationRequestCreateResource(getTriggerEngineContent(state)));

        if (this.getActionState().sendPush) {
            request.push(new BulkOperationRequestSendPushNotification({ notification: NOTIFICATION_TYPE.RESOURCE }));
        }

        return request;
    }
}
